.suggestionCard .ant-card-head .ant-card-head-title {
    padding-bottom: 0px !important;
}

.suggestionCard .ant-card-head .ant-card-head-title {
    padding-bottom: 0px !important;
}

.sales-card .ant-card-body {
    border: 1px black solid;
    padding: 2px;
}

.card-content {
    display: flex;
}

.left-section {
    flex: 0 0 20%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    border-right: 1px black solid;
}

.product-image {
    width: 100%;
    height: auto;
    padding: 3px;
    margin-bottom: 8px;
}

.barcode {
    font-weight: bold;
    font-size: 11px;
    padding-right: 1px;
    border-top: 1px black solid;
}

.right-section {
    flex: 1;
    padding-left: 16px;
    display: flex;
}

.sub-left,
.sub-right {
    flex: 1;
    display: flex;
    flex-direction: column;
}

.sub-left {
    flex: 0 0 45%;
    margin-right: 8px;
    margin-top: 10px;
    overflow: hidden;
}

.sub-left-app-inventory {
    flex: 0 0 45%;
    margin-right: 8px;
    margin-top: 10px;
    overflow: hidden;
}

.sub-right {
    flex: 1;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    font-size: 14px;
    margin-bottom: 15px;
}

.info-row {
    display: flex;
    justify-content: space-between;
    margin-bottom: 8px;
    font-weight: 600;
    font-size: 11px;
    overflow: hidden;
}

.info-right {
    display: flex;
    justify-content: center;
    font-weight: bold;
}

.mt-2 {
    margin-top: 8px;
}

.w-100 {
    width: 100%;
}

.sle-btn {
    margin-top: 4px;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 70%;
    font-size: 13px;
}