.reportCard {
    background-color: #fff;
    border-radius: 10px;
    border: none;
    position: relative;
    margin-bottom: 30px;
    box-shadow: 0 0.46875rem 2.1875rem rgba(90,97,105,0.1), 0 0.9375rem 1.40625rem rgba(90,97,105,0.1), 0 0.25rem 0.53125rem rgba(90,97,105,0.12), 0 0.125rem 0.1875rem rgba(90,97,105,0.1);
}
.l-bg-cherry {
    background: linear-gradient(to right,#0060AA ,#00ACE7 ) !important;
    color: #fff;
}

.reportCard .reportCard-statistic-3 .reportCard-icon-large .fas, .reportCard .reportCard-statistic-3 .reportCard-icon-large .far, .reportCard .reportCard-statistic-3 .reportCard-icon-large .fab, .reportCard .reportCard-statistic-3 .reportCard-icon-large .fal {
    font-size: 75px;
}

.reportCard .reportCard-statistic-3 .reportCard-icon {
    text-align: center;
    line-height: 50px;
    margin-left: 15px;
    color: #000;
    position: absolute;
    right: 5px;
    top: 10px;
    opacity: 0.1;
}
