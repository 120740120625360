.brandmobile-page {
    padding: 20px;
    background-color: white;
  }
  
  h4 {
    font-size: 1em;
    font-weight: bold;
    margin-bottom: 15px;
  }
  
  h4.img {
    position: fixed;
  }
  
  .brandmobile-grid {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(120px, 1fr));
    gap: 20px;
    justify-content: center;
    place-items: center;
  }
  
  .brandmobile-card {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    padding: 6px;
    background-color: white;
    transition: transform 0.3s ease, box-shadow 0.3s ease;
    cursor: pointer;
    border-radius: 25px;
    width: 120px;
    height: 50px;
    border: 1px solid black;
  }
  
  .brandmobile-card:hover {
    transform: translateY(-3px);
    box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.1);
  }
  
  .brandmobile-card img {
    width: 80px;
    height: 80px;
    object-fit: contain;
    background-color: white;
    padding: 3px;
  }
  
  .brandmobile-name {
    font-size: 0.9em;
    font-weight: 500;
    color: #333;
    margin-top: 3px;
    text-align: center;
  }
  
  .brandmobile-arrow {
    font-size: 1em;
    color: black;
  }
  