.category-page {
  padding: 20px;
}

li{
  list-style: none !important;
}

h4 {
  font-size: 1.5em;
  font-weight: bold;
  margin-bottom: 10px;
  display: flex;
  align-items: center;
}

h4 img {
  margin-right: 10px;
  cursor: pointer;
}

.category-grid {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(80px, 1fr));
  gap: 20px;
  justify-content: center;
}

.category-card {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  background-color: #D9D9D9;
  padding: 4px;
  border-radius: 10px;
  box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s ease, box-shadow 0.3s ease;
  cursor: pointer;
}

.category-card:hover {
  transform: translateY(-5px);
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.2);
}

.category-card img {
  width: 80px;
  height: 80px;
  object-fit: contain;
  background-color: #D9D9D9;
  padding: 2px;
  border: 2px solid #D9D9D9;
}

.category-name {
  font-size: 0.9em;
  font-weight: 600;
  margin-top: 5px;
  color: #333;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
}

.category-name img {
  width: 12px;
  height: 12px;
  margin-left: 5px;
}


.styleButton{
  border: none !important;
  /* width: 100% !important; */
  min-width: 125px;
}

.styleDrawerHeader div div div .ant-drawer-header{
  background-color: #0060aa;
  color: white !important;
}
